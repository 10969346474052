<template>
    <footer class="px-4 py-6 bg-blue-500 text-gray-100">
            <div class="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-4">
                <div class="text-base text-gray-200">
                <p class="mb-2 font-semibold">Contact Us:</p>
                <p>Email: 
                    <a title="Email us" :href="`mailto:support@${appName}.co.ke`">support@{{appName}}.co.ke</a>
                </p>
                <p class="flex flex-wrap gap-x-1">
                    <span>Phone:</span> 
                    <a title="Call us" href="tel:+254799088611">+254799088611</a>
                </p>
                <NavLink :href="route('guest.contact')">
                    <span class="text-gray-100 text-base">Contact us</span>
                </NavLink>
            </div>

        <!-- Company Info Section -->
        <div class="flex flex-col space-y-3">
            <h3 class="font-bold">About {{ appName }}</h3>
            <p>{{ slogan }}</p>
            <div>
                <NavLink :href="route('guest.about')">
                    <span class="text-gray-100">Learn more</span>
                </NavLink>
            </div>
            <div class="hidden">
                <NavLink :href="route('guest.faqs')">
                    <span class="text-gray-100">FAQs</span>
                </NavLink>
            </div>
        </div>

        <!-- Legal & Contact Links -->
        <div class="flex flex-col space-y-2 text-base">
            <h3 class="font-bold text-lg">Important Links</h3>
            <ul class="space-y-2">
                <li>
                    <button @click="signIn" title="Login" class="inline-flex items-center px-1 pt-1 border-transparent font-medium leading-5 text-gray-100 hover:underline hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300  transition duration-150 ease-in-out">Login</button>
                </li>
                <li>
                    <button @click="signUp" title="Create Account" class="inline-flex items-center px-1 pt-1 border-transparent font-medium leading-5 text-gray-100 hover:underline hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300  transition duration-150 ease-in-out">Create Account</button>
                </li>
                <li>
                    <NavLink :href="route('guest.terms')" :active="route().current('guest.terms')">
                        <span class="text-gray-100 text-base">Terms of Service</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink :href="route('guest.privacy.policy')" :active="route().current('guest.privacy.policy')">
                        <span class="text-gray-100 text-base">Privacy Policy</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink :href="route('guest.cookie.policy')" :active="route().current('guest.cookie.policy')">
                        <span class="text-gray-100 text-base">Cookie Policy</span>
                    </NavLink>
                </li>
            </ul>
        </div>
        
        <!-- Social Media Links -->
        <div class="flex flex-col">
            <h3 class="font-bold">Our Socials</h3>
            <ul class="flex gap-x-4">
                <li>
                    <a href="https://wa.me/254799088611?text=Hi%20there!%20I%20am%20interested%20in%20learning%20more%20about%20your%20POS%20system" class="hover:underline" target="_blank" title="Chat on Whatsapp">
                        <font-awesome-icon icon="fa-brands fa-whatsapp" class="text-lg md:text-2xl" />
                    </a>
                </li>
                <li>
                    <a href="https://facebook.com/profile.php?id=61559158606458" class="hover:underline" target="_blank" title="Our Facebook page">
                        <font-awesome-icon icon="fa-brands fa-facebook" class="text-lg md:text-2xl" />
                    </a>
                </li>
            </ul>
        </div>
    </div>
    
    <!-- Bottom Footer -->
    <div class="mt-6 text-center gap-y-1 text-sm text-gray-200 flex-wrap sm:flex-nowrap flex gap-x-2 justify-center">
        <p>&copy; {{ currentYear }} {{ appName }} </p>
        <p class="border-l sm:border-x border-x-gray-300 px-2">All Rights Reserved</p>
        <p class="w-full sm:w-auto">
            <span>Made by </span>
            <a href="https://provydesigns.africa" target="_blank" class="hover:underline">Provy Designs</a>
            <font-awesome-icon icon="arrow-up-right-from-square" class="ml-1" />
        </p>
    </div
    <BackToTop />
</footer>
</template>

<script setup>
// import BackToTop from '@Components/BackToTop.vue';
import BackToTop from './BackToTop.vue';
import helpers from '@/Globals/helpers';
import NavLink from './NavLink.vue';

const date = new Date();
const currentYear = date.getFullYear();
const appName = helpers.appName;
const slogan = helpers.slogan;
const signIn = helpers.signIn;
const signUp = helpers.signUp;
</script>